/* Generated by Font Squirrel (http://www.fontsquirrel.com) */

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik-Regular-Web.eot');
  src: url('/fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Graphik-Regular-Web.woff') format('woff'),
  url('/fonts/Graphik-Regular-Web.ttf') format('truetype'),
  url('/fonts/Graphik-Regular-Web.svg#graphik_webregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/graphik-semibold-webfont.eot');
  src: url('/fonts/graphik-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/graphik-semibold-webfont.woff') format('woff'),
  url('/fonts/graphik-semibold-webfont.ttf') format('truetype'),
  url('/fonts/graphik-semibold-webfont.svg#graphiksemibold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik-Medium.eot');
  src: url('/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Graphik-Medium.woff') format('woff'),
  url('/fonts/Graphik-Medium.ttf') format('truetype'),
  url('/fonts/Graphik-Medium.svg#Graphik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Semibold';
  src: url('/fonts/graphik-semibold-webfont.eot');
  src: url('/fonts/graphik-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/graphik-semibold-webfont.woff') format('woff'),
  url('/fonts/graphik-semibold-webfont.ttf') format('truetype'),
  url('/fonts/graphik-semibold-webfont.svg#graphiksemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-Bold.eot');
  src: url('/fonts/Editor-Bold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-Bold.woff2') format('woff2'),
          url('/fonts/Editor-Bold.woff') format('woff'),
          url('/fonts/Editor-Bold.ttf') format('truetype'),
          url('/fonts/Editor-Bold.svg#Editor-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-ExtraboldItalic.eot');
  src: url('/fonts/Editor-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-ExtraboldItalic.woff2') format('woff2'),
          url('/fonts/Editor-ExtraboldItalic.woff') format('woff'),
          url('/fonts/Editor-ExtraboldItalic.ttf') format('truetype'),
          url('/fonts/Editor-ExtraboldItalic.svg#Editor-ExtraboldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-Extrabold.eot');
  src: url('/fonts/Editor-Extrabold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-Extrabold.woff2') format('woff2'),
          url('/fonts/Editor-Extrabold.woff') format('woff'),
          url('/fonts/Editor-Extrabold.ttf') format('truetype'),
          url('/fonts/Editor-Extrabold.svg#Editor-Extrabold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-Light.eot');
  src: url('/fonts/Editor-Light.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-Light.woff2') format('woff2'),
          url('/fonts/Editor-Light.woff') format('woff'),
          url('/fonts/Editor-Light.ttf') format('truetype'),
          url('/fonts/Editor-Light.svg#Editor-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-Regular.eot');
  src: url('/fonts/Editor-Regular.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-Regular.woff2') format('woff2'),
          url('/fonts/Editor-Regular.woff') format('woff'),
          url('/fonts/Editor-Regular.ttf') format('truetype'),
          url('/fonts/Editor-Regular.svg#Editor-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Editor';
  src: url('/fonts/Editor-Italic.eot');
  src: url('/fonts/Editor-Italic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/Editor-Italic.woff2') format('woff2'),
          url('/fonts/Editor-Italic.woff') format('woff'),
          url('/fonts/Editor-Italic.ttf') format('truetype'),
          url('/fonts/Editor-Italic.svg#Editor-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/fonts/SFProText-BoldItalic.eot');
  src: url('/fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-BoldItalic.woff2') format('woff2'),
          url('/fonts/SFProText-BoldItalic.woff') format('woff'),
          url('/fonts/SFProText-BoldItalic.ttf') format('truetype'),
          url('/fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('/fonts/SFProText-Ultralight.eot');
  src: url('/fonts/SFProText-Ultralight.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-Ultralight.woff2') format('woff2'),
          url('/fonts/SFProText-Ultralight.woff') format('woff'),
          url('/fonts/SFProText-Ultralight.ttf') format('truetype'),
          url('/fonts/SFProText-Ultralight.svg#SFProText-Ultralight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/fonts/SFProText-RegularItalic.eot');
  src: url('/fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-RegularItalic.woff2') format('woff2'),
          url('/fonts/SFProText-RegularItalic.woff') format('woff'),
          url('/fonts/SFProText-RegularItalic.ttf') format('truetype'),
          url('/fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/fonts/SFProText-Semibold.eot');
  src: url('/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-Semibold.woff2') format('woff2'),
          url('/fonts/SFProText-Semibold.woff') format('woff'),
          url('/fonts/SFProText-Semibold.ttf') format('truetype'),
          url('/fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('/fonts/SFProText-Ultralight_1.eot');
  src: url('/fonts/SFProText-Ultralight_1.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-Ultralight_1.woff2') format('woff2'),
          url('/fonts/SFProText-Ultralight_1.woff') format('woff'),
          url('/fonts/SFProText-Ultralight_1.ttf') format('truetype'),
          url('/fonts/SFProText-Ultralight_1.svg#SFProText-Ultralight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('/fonts/SFProText-Light.eot');
  src: url('/fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
          url('/fonts/SFProText-Light.woff2') format('woff2'),
          url('/fonts/SFProText-Light.woff') format('woff'),
          url('/fonts/SFProText-Light.ttf') format('truetype'),
          url('/fonts/SFProText-Light.svg#SFProText-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('/fonts/Outfit-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('/fonts/Outfit-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('/fonts/Outfit-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('/fonts/Outfit-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesDisplay';
  src: url('/fonts/EssonnesDisplay-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesDisplay';
  src: url('/fonts/EssonnesDisplay-Regular.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesDisplay';
  src: url('/fonts/EssonnesDisplay-Light.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesDisplay';
  src: url('/fonts/EssonnesDisplay-LightItalic.otf') format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesHeadline';
  src: url('/fonts/EssonnesHeadline-Regular.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesHeadline';
  src: url('/fonts/EssonnesHeadline-RegularItalic.otf') format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesHeadline';
  src: url('/fonts/EssonnesHeadline-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesText';
  src: url('/fonts/EssonnesText-Regular.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesText';
  src: url('/fonts/EssonnesText-RegularItalic.otf') format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesText';
  src: url('/fonts/EssonnesText-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EssonnesText';
  src: url('/fonts/EssonnesText-BoldItalic.otf') format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Concrette';
  src: url('/fonts/ConcretteM-Medium.woff2') format('woff2');
  src: url('/fonts/ConcretteM-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Concrette';
  src: url('/fonts/ConcretteM-MediumItalic.woff2') format('woff2');
  src: url('/fonts/ConcretteM-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Concrette';
  src: url('/fonts/ConcretteM-RegularItalic.woff2') format('woff2');
  src: url('/fonts/ConcretteM-RegularItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Concrette';
  src: url('/fonts/ConcretteM-Regular.woff2') format('woff2');
  src: url('/fonts/ConcretteM-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Grotesk';
  src: url('/fonts/FKGroteskNeue-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Grotesk';
  src: url('/fonts/FKGroteskNeue-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Grotesk';
  src: url('/fonts/FKGroteskNeue-Italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Grotesk';
  src: url('/fonts/FKGroteskNeue-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}